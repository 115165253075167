import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="service"
          className={`${this.props.article === 'service' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Service</h2>
          <span className="image main">
            <img src={pic01} alt="starlinking" />
          </span>
          <p>
            <h3>Solution Consulting</h3>
	    <br />
	    We are not only focus on technologies but also applied scenarios to help our customers 
	    time to market to make win-win strategies.
	    Services we deliver
	    <br />
	    <ul>
                <li>Business Strategy</li>
                <li>Partnertship Engagement</li>
                <li>One-stop Cloud Services for your projects</li>
                <li>Accelerated IoT and Smart City Solution Landing</li>
                <li>End to End System Integration Services</li>
                <li>Power Machine Learning and Big Data Analysis</li>
                <li>AI Application Scenarios</li>
            </ul>
	    Check out our awesome usecase on landing page.
          </p>
          <p>
            <h3>Engineering Resources</h3>
	    <br />
	    Team senior members all are experienced staff engineers and domain experts from related 
	    industries over 10 years.
	  </p>
	  <p>
	    <h3>Project Landing</h3>
	    <br />
	    To be confident with high-achieving rate to our customers and trust coroperation.
          </p>
          {close}
        </article>

        <article
          id="usecase"
          className={`${this.props.article === 'usecase' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">UseCase</h2>
          <span className="image main">
            <img src={pic02} alt="starlinking" />
          </span>
          <p>
            <h3>Smart Meter</h3>
	    <br />
	    We did cloud migration from VMware to HUAWEI Cloud for <a href="http://www.tatung.com.tw" target="_blank" rel="noreferrer noopener">Tatung</a> smart meter team and joined HUAWEI oversea pilots as well.  
          </p>
          <p>
            <h3>Obstructive Sleep Apnea Syndrome (OSAS)</h3>
	    <br />
	     We did cloud service integration consulting for <a href="http://www.soteriabio.com/" target="_blank" rel="noreferrer noopener">Soteria Biotech</a> application team. A highly demand usecase is from a standalone application to multi-user cloud services. 
          </p>
          <p>
            <h3>Cardiotocography (CTG)</h3>
	    <br />
	     We did cloud service integration consulting from AWS to HUAWEI Cloud for <a href="https://sehati.co/en/" target="_blank" rel="noreferrer noopener">Sehati teleCTG</a> application team. 
          </p>
          <p>
            <h3>IoT Liguid Level Detection Total Solution</h3>
	    <br />
	     We do IoT device and platform planning and system integration for <a href="https://www.5giotlead.com/" target="_blank" rel="noreferrer noopener">5GIoTLead</a> product development team. 
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={pic03} alt="starlinking" />
          </span>
	  <p>
	    StarLinking Networks is a Taiwan company located in Taipei that started in 2018 as a smart IoT solution provider and consulting services for global customers.  
	  </p>  
          <p>
            StarLinking Networks with a vision: Bringing smart solutions to every domain customer in order to resolve their pain points in a simple, fast, and affordable manner.
          </p>
	  <p>
	    StarLinking Networks is <a href="https://www.huaweicloud.com/intl/en-us/" target="_blank" rel="noreferrer noopener" >HUAWEI CLOUD</a> consulting and solution partner. 
	  </p>  
	  <p>
	    StarLinking Networks is <a href="https://thingsboard.io/" target="_blank" rel="noreferrer noopener" >ThingsBoard</a> IoT platform solution distributor. 
	  </p>  
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
	  <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />  
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://starlinking.com"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://starlinking.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://starlinking.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://starlinking.com"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
